<template>
  <div class="">
    <form class="w-full" @submit.prevent="onSubmit">
      <div class="container mx-auto py-6 px-4">
        <div class="flex justify-between">
          <h2 class="text-2xl font-bold mb-6 pb-2 tracking-wider uppercase">
            Float Replenishment
          </h2>
        </div>

        <div class="flex flex-wrap justify-between mb-8">
          <div class="w-full md:w-1/3 mb-2 md:mb-0">
            <label
              class="
                text-gray-800
                block
                mb-1
                font-bold
                text-sm
                uppercase
                tracking-wide
              "
              >To:</label
            >
            <input
              class="
                mb-1
                appearance-none
                border border-gray-200
                rounded
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-blue-500
              "
              readonly
              type="text"
              placeholder="name"
              v-model="form.name"
            />
            <input
              class="
                mb-1
                appearance-none
                border border-gray-200
                rounded
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-blue-500
              "
              type="date"
              v-model="form.date"
            />
          </div>
          <div class="w-full md:w-1/3">
            <label
              class="
                text-gray-800
                block
                mb-1
                font-bold
                text-sm
                uppercase
                tracking-wide
              "
              >Bank Deposit Details:</label
            >
            <input
              class="
                mb-1
                appearance-none
                border border-gray-200
                rounded
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-blue-500
              "
              type="text"
              placeholder="Enter Bank Name"
              v-model="form.bank"
            />

            <input
              class="
                mb-1
                appearance-none
                border border-gray-200
                rounded
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-blue-500
              "
              type="text"
              placeholder="Enter Deposit Slip Number"
              v-model="form.bankSlip"
            />
            <div>
              <div
                class="
                  w-full
                  h-10
                  mb-1
                  border
                  rounded
                  overflow-hidden
                  relative
                  bg-gray-100
                "
              >
                <img
                  id="image"
                  class="object-cover w-full h-32"
                  src="https://placehold.co/300x300/e2e8f0/e2e8f0"
                />

                <div
                  class="
                    absolute
                    top-0
                    left-0
                    right-0
                    bottom-0
                    w-full
                    block
                    cursor-pointer
                    flex
                    pl-4
                    items-center
                    justify-between
                  "
                  onClick="document.getElementById('fileInput').click()"
                >
                  <span class="text-gray-500"> Upload deposit slip </span>
                  <button
                    type="button"
                    style="background-color: rgba(255, 255, 255, 0.65)"
                    class="
                      hover:bg-gray-100
                      text-gray-700
                      font-semibold
                      py-2
                      px-4
                      text-sm
                      border border-gray-300
                      rounded-lg
                      shadow-sm
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-camera"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        stroke="none"
                      ></rect>
                      <path
                        d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"
                      />
                      <circle cx="12" cy="13" r="3" />
                    </svg>
                  </button>
                </div>
              </div>
              <input
                name="photo"
                id="fileInput"
                accept="image/*"
                class="hidden"
                type="file"
                onChange="let file = this.files[0]; 
					var reader = new FileReader();

					reader.onload = function (e) {
						document.getElementById('image').src = e.target.result;
						document.getElementById('image2').src = e.target.result;
					};
				
					reader.readAsDataURL(file);
				"
              />
            </div>

            <input
              class="
                mb-1
                mt-6
                appearance-none
                border border-gray-200
                rounded
                w-full
                py-3
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-blue-500
              "
              type="number"
              placeholder="Enter Total Amount Deposited (GMD)"
              v-model="form.amount"
            />
          </div>
        </div>

        <div class="flex -mx-1 border-b py-2 items-start">
          <div class="flex-1 px-1">
            <p class="text-gray-800 uppercase tracking-wide text-sm font-bold">
              Description
            </p>
          </div>

          <div class="px-1 w-52 text-right">
            <p class="leading-none">
              <span
                class="
                  block
                  uppercase
                  tracking-wide
                  text-sm
                  font-bold
                  text-gray-800
                "
                >Current Balance</span
              >
              <span class="font-medium text-xs text-gray-500">(GMD)</span>
            </p>
          </div>

          <div class="px-1 w-52 text-right">
            <p class="leading-none">
              <span
                class="
                  block
                  uppercase
                  tracking-wide
                  text-sm
                  font-bold
                  text-gray-800
                "
                >Allocate Amount</span
              >
              <span class="font-medium text-xs text-gray-500">(GMD)</span>
            </p>
          </div>
        </div>
        <template>
          <div
            v-for="(allocation, i) in form.allocations"
            :key="i"
            class="flex -mx-1 py-2 border-b"
          >
            <div class="flex-1 px-1">
              <p class="text-gray-800">{{ allocation.name }}</p>
            </div>

            <div class="px-1 w-52 text-right">
              <p class="text-gray-800">
                {{ formatPrice(allocation.actualbalance) }}
              </p>
            </div>

            <div class="w-52 text-right">
              <input
                type="number"
                v-if="allocation.actualbalance === 140000"
                readonly
                :value="balanceToAllocate"
                placeholder="Enter amount"
                class="text-right"
              />

              <input
                type="number"
                v-else
                v-model="allocation.amount_allocated"
                placeholder="Enter amount"
                class="text-right"
              />
            </div>
          </div>
        </template>

        <p
          v-if="balanceToAllocate > 0"
          class="bg-green-200 mt-5 text-center py-2 rounded-md"
        >
          Please note the remaining balance of
          {{ formatPrice(balanceToAllocate) }} will be added to the buffer
          account.
        </p>

        <div class="flex">
          <textarea
            v-model="form.remarks"
            name=""
            class="mt-4 border p-2"
            cols="50"
            rows="5"
            placeholder="Enter remarks here"
          ></textarea>

          <div class="py-2 ml-auto mt-5 w-full sm:w-2/4 lg:w-1/4">
            <div class="py-2 border-t border-b">
              <div class="flex justify-between">
                <div class="text-xl text-gray-600 text-right flex-1">
                  Balance To Allocate
                </div>
                <div class="text-right w-40">
                  <div class="text-xl text-gray-800 font-bold">
                    {{ formatPrice(balanceToAllocate) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="hasError" class="my-8" role="alert">
          <div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">
            {{ errorData.message }}
          </div>
          <div
            class="
              border border-t-0 border-red-400
              rounded-b
              bg-red-100
              px-4
              py-3
              text-red-700
            "
          >
            <p v-for="(error, i) in errorData.errors" :key="i">
              {{ error[0] }}
            </p>
          </div>
        </div>

        <div class="text-right">
          <button
            class="
              px-4
              py-2
              rounded
              text-white
              inline-block
              shadow-lg
              bg-blue-500
              hover:bg-blue-600
              focus:bg-blue-700
            "
            :class="{ 'opacity-50 cursor-not-allowed': isLoading }"
            type="submit"
            :disabled="isLoading"
          >
            <span v-if="isLoading">Loading...</span>
            <span v-else>Submit</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import FormatPrice from "@/mixins/FormatPrice";

export default {
  middleware: "auth",
  layout: "default",

  mixins: [FormatPrice],

  data: () => ({
    // allocations: [],
    isLoading: false,
    form: new Form({
      name: "Nawec",
      date: "",
      bankSlip: "",
      bank: "",
      amount: 0,
      allocations: [],
      remarks: "",

      remainingBalance: 0,
    }),

    hasError: false,
    errorData: null,
  }),

  mounted() {
    console.log("Loading Balances");
    this.loadBalance();
  },

  computed: {
    balanceToAllocate() {
      var sum = 0;
      this.form.allocations.forEach((e) => {
        sum += parseInt(e.amount_allocated);
      });

      return Math.abs(sum - parseInt(this.form.amount));
    },
  },

  methods: {
    async loadBalance() {
      try {
        const { data } = await axios.get(`/accounts`);

        console.log("Response", data);

        let list = [];

        data.data.map(function (value) {
          list.push({
            id: value.id,
            name: value.name,
            actualbalance: value.balance,
            amount_allocated: 0,
          });
        });

        this.form.allocations = list;

        return true;
      } catch (e) {
        console.log("Error", e);
        return false;
      }
    },

    async onSubmit() {
      // Submit the form
      this.isLoading = true;

      this.form.remainingBalance = this.balanceToAllocate;

      try {
        await this.form.post("/float/store");

        this.isLoading = false;
        // manage_floats
        this.$router.push({ path: "/manage_floats" });

        return true;
      } catch (e) {
        this.isLoading = false;

        if (e.response.status === 422) {
          this.hasError = true;
          this.errorData = e.response.data;

          setTimeout(function () {
            this.hasError = false;
          }, 1000); //wait 10 seconds

          console.log("Error", e.response);
        }
        return false;
      }
    },
  },
};
</script>